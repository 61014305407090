import React from 'react';
import { graphql, Link } from 'gatsby';

import LayoutSimple from '../components/layout-simple';
import Seo from '../components/seo';

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <LayoutSimple location={location} title={siteTitle} showPageHeader={false} showSidebar={false}>
      <Seo title="404: Not Found" />
      <div className="col-12 text-center error-page">
        <h1>404!</h1>
        <h4 className="error-title">Sorry, page not found</h4>
        <p className="error-text text-muted mt-3">Use the navigation above or the button below to get back and track.</p>
        <Link to="/" className="btn btn-outline-custom mt-4">BACK TO HOME PAGE</Link>
      </div>
    </LayoutSimple>
  )
}

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
